.about_us_cont .about_us_block {
  margin-bottom: 20px;
  line-height: 1.5;
}

.about_us_cont div {
  line-height: 1.5;
}

.main_with_left h1 {
  font-size: 20px;
  margin: 0 0 15px;
  color: #000;
  text-transform: uppercase;
  font-family: Montserrat;
  border-bottom: none;
}

.expel_class {
  margin-bottom: 20px;
}

.expel_class:last-child {
  margin-bottom: 0;
}
