.button_green {
  background-color: #68ac1d;
  border: none;
  font-family: Roboto;
  padding: 9px 20px;
  text-align: center;
  color: #fff;
  margin-left: 25px;
}

body[dir="rtl"] .button_green {
  margin-right: 25px;
  margin-left: 0;
}

.button_green:hover {
  background: #84b433;
}

.button_green div:first-child {
  font-size: 14px;
  font-weight: bold;
}

.button_green div:last-child {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
}

@media (max-width: 550px) {
  .button_green div:last-child {
    display: none;
  }
}
