/*lang*/
.en {
  background-position: -204px -1px;
}
.ru {
  background-position: -407px -1px;
}
.es {
  background-position: -1px -22px;
}
.pt {
  background-position: -30px -22px;
}
.ar {
  background-position: -59px -22px;
}
.fa {
  background-position: -88px -22px;
}
.tr {
  background-position: -494px -1px;
}
.vi {
  background-position: -117px -22px;
}
.ja {
  background-position: -262px -1px;
}
.ID {
  background-position: -175px -22px;
}
.fr {
  background-position: -204px -22px;
}
.cs {
  background-position: -233px -22px;
}
.hi {
  background-position: -262px -22px;
}
.de {
  background-position: -291px -22px;
}
.it {
  background-position: -320px -22px;
}
.ms {
  background-position: -291px -106px;
}
.zh {
  background-position: -146px -22px;
}
.zh_cn {
  background-position: -88px -1px;
}
.w-100 {
  width: 100% !important;
}
.dropdown-menu-right {
  text-align: right;
}
.text-right {
  text-align: right;
}
.menu .container {
  margin-bottom: 0;
}

.menu {
  font-family: Roboto;
  font-weight: 400;
  background: #1a1a1a;
}

.top_menu_wrapper ul {
  display: flex;
  justify-content: flex-end;
}

.top_menu_wrapper,
.top_menu_wrapper ul,
.top_menu_wrapper ul li,
.top_menu_wrapper ul li a,
.top_menu_wrapper #menu-button {
  line-height: 1;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  justify-content: flex-end;
}

.top_menu_wrapper #menu-button {
  display: none;
}

.top_menu_wrapper > ul > li {
  float: left;
}

body[dir="rtl"] .top_menu_wrapper > ul > li {
  float: right;
}

.top_menu_wrapper > ul > li > a {
  font-size: 14px;
  padding: 15px;
  text-decoration: none;
  letter-spacing: 1px;
  color: #ddd;
}

body[dir="rtl"] .top_menu_wrapper > ul > li > a {
  padding: 15px;
}

.top_menu_wrapper > ul > li {
  position: relative;
}

.top_menu_wrapper > ul > li a:hover,
.top_menu_wrapper > ul > li a.toggable.active {
  background-color: #494949;
}

.top_menu_wrapper > ul > li .toggable.active:before,
.top_menu_wrapper > ul > li a:hover:before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #68ac1d;
  position: absolute;
  left: 0px;
  bottom: 0;
}

body[dir="rtl"] .top_menu_wrapper > ul > li a:hover:before {
  right: 0px;
  left: auto;
}

.menu .lang_menu,
.menu .watch {
  display: none;
}

.right_part {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right_part .lang_menu,
.right_part .watch {
  margin-left: 25px;
}

body[dir="rtl"] .right_part .lang_menu,
body[dir="rtl"] .right_part .watch {
  margin-right: 25px;
  margin-left: 0;
}

#menu_button,
.close {
  display: none;
}

.lang_menu .flag,
.watch {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (min-width: 993px) {
  .top_menu_wrapper ul {
    width: 100% !important;
  }
}

/*---------------------------------- MOBILE MENU----------------------------------*/
.menu_list .closebtn,
#menu_button {
  display: none;
}

@media (max-width: 992px) {
  .dropdown-menu-right:before {
    position: absolute;
    top: 18px;
    left: 15px;
    display: inline-block;
    width: 8px;
    height: 8px;
    content: "";
    transform: rotate(45deg);
    pointer-events: none;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  .menu .container {
    display: flex;
    justify-content: flex-end;
  }

  .menu {
    padding: 5px 0;
  }

  .menu_list {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden;
    width: 0;
    height: 100%;
    padding-top: 60px;
    transition: 0.5s;
    background-color: #1a1a1a;
  }

  #menu_button {
    display: inline-block;
    color: #fff;
    margin-left: 20px;
  }

  .top_menu_wrapper {
    right: 0;
    top: 70px;
    background: #fff;
    position: fixed;
    display: block;
    height: 100%;
  }

  .top_menu_wrapper > ul > li > a {
    opacity: 0;
    transition: 0.1s ease-in;
  }

  .top_menu_wrapper ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: #1a1a1a;
  }

  .top_menu_wrapper ul {
    justify-content: flex-start;
    position: absolute;
    z-index: 2;
    display: block;
    overflow: auto;
    height: 100%;
    width: 250px;
    right: -250px;
  }

  .top_menu_wrapper,
  .top_menu_wrapper ul {
    z-index: 2;
    flex-direction: column;
    align-items: flex-end;
  }

  .top_menu_wrapper > ul > li {
    width: 100%;
  }

  .top_menu_wrapper > ul > li:first-child {
    padding: 0 15px;
  }

  .menu_list .closebtn {
    font-size: 36px;
    position: relative;
    top: 0;
    display: block;
  }

  .body_hover .menu_list a {
    width: 100%;
    display: inline-block;
    text-align: right !important;
    padding: 15px;
    transition: 0.3s ease-in;
    opacity: 1;
  }

  body[dir="rtl"] .body_hover .menu_list a {
    text-align: left;
  }

  .body_hover {
    overflow: hidden;
  }

  .body_hover:after {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.5;
    background: #000;
  }
}

@media (max-width: 800px) {
  .menu .container {
    justify-content: space-between;
  }

  .menu .lang_menu {
    display: inline-block;
  }

  .right_part .lang_menu {
    display: none;
  }
}

@media (max-width: 550px) {
  .top_menu_wrapper {
    top: 58px;
  }
}

@media (max-width: 450px) {
  .menu_list {
    padding-top: 15px;
  }

  .menu_list a {
    font-size: 18px;
  }
}

/*.dropdown-content {*/
/*    display: none;*/
/*    background-color: #f9f9f9;*/
/*    min-width: 160px;*/
/*    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
/*    z-index: 1;*/
/*}*/

ul li:hover ul.dropdown-content {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

ul ul.dropdown-content {
  display: flex;
  background-color: #2f3640;
  flex-flow: column;
  position: absolute;
  top: 40px;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  -webkit-transform: translate3d(0, 30%, 0);
  transform: translate3d(0, 30%, 0);
  visibility: hidden;
  z-index: 1000;
  width: 200px !important;
}

ul ul.dropdown-content li a {
  padding: 12px 16px;
  width: 100%;
}
ul ul.dropdown-content li a {
  color: #fff;
}

ul ul.dropdown-content a:hover {
  background-color: initial;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}
/*---------------------------------- END MOBILE MENU----------------------------------*/
