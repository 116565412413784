* {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: #fff;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background: #cccccc;
  height: 80px;
}

body {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  position: initial;
  overflow: auto;
  margin: 0;
  text-align: justify;
  color: #4a4a4a;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside none;
}

.ul-tick {
  position: relative;
}

.ul-tick li {
  line-height: 26px;
  position: relative;
  padding-left: 30px;
  text-align: left;
}

body[dir="rtl"] .ul-tick li {
  padding-right: 30px;
  padding-left: 0;
  text-align: right;
}

.ul-tick li:before {
  font-family: "gorexgrad";
  font-size: 13px;
  line-height: 25px;
  position: absolute;
  left: 0;
  content: "\E807";
  color: #7bab2e;
}

body[dir="rtl"] .ul-tick li:before {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
/*@media (min-width: 576px){*/
/*.container {*/
/*width: 540px;*/
/*max-width: 100%;*/
/*padding-right: 15px;*/
/*padding-left: 15px;*/
/*}*/
/*}*/
/*@media (min-width: 768px){*/
/*.container {*/
/*width: 720px;*/
/*max-width: 100%;*/
/*padding-right: 15px;*/
/*padding-left: 15px;*/
/*}*/
/*}*/
/*@media (min-width: 992px){*/
/*.container {*/
/*width: 960px;*/
/*max-width: 100%;*/
/*padding-right: 30px;*/
/*padding-left: 30px;*/
/*}*/
/*}*/
@media (min-width: 1200px) {
  .container {
    width: 1440px;
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}

/*@media (min-width: 1365px){*/
/*.container{*/
/*max-width: 1200px;*/
/*}*/
/*}*/
/*@media (min-width: 2500px) {*/
/*.container{*/
/*max-width: 1440px;*/
/*}*/
/*}*/

.container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.content .container:last-child {
  margin-bottom: 0;
}

.content {
  padding-top: 30px;
  padding-bottom: 50px;
  background: #f1f1f1;
}
.arrow {
  position: relative;
  margin-left: 4px;
  top: -1px;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  vertical-align: 2px;
  border: solid #1d1d1d;
  border-width: 0 1px 1px 0;
}

body[dir="rtl"] .arrow {
  margin-right: 4px;
  margin-left: 0;
}

p {
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

body[dir="rtl"] p {
  text-align: right;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 17px;
}

table th,
table tr {
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8;
}

table th {
  color: #717171;
  font-size: 12px;
  padding: 10px 7px;
  font-weight: normal;
}
table td {
  padding: 10px 5px;
}

a {
  transition: 0.4s;
  text-decoration: none;
  color: #4a90e2;
}

.main_with_left h1{
  padding-bottom: 0;
}

h2,
.main_with_right h1,
.main_with_left h1,
.widthcalendarTab h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: 0 0 15px;
  text-align: left;
  text-transform: uppercase;
  color: #000;
}

h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 10px;
  color: #000;
  text-align: left;
}

body[dir="rtl"] h2,
body[dir="rtl"] h3 {
  text-align: right;
}

.main_half {
  display: inline-block;
}

.amcharts-chart-div a {
  display: none !important;
}

.widthcalendar {
  width: 50%;
}
.widthcalendarTab {
  width: 100%;
}

.main_full_width {
  display: inline-block;
  width: 100%;
}
.main_with_left.commeasurable {
  display: flex;
  flex-direction: column;
}
.commeasurable > div {
  height: 100%;
}
.main_with_left {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 20px;
}

body[dir="rtl"] .main_with_left {
  margin-left: 20px;
  margin-right: 0;
}

.main_with_right {
  display: inline-block;
  width: 70%;
  margin-left: 20px;
}

body[dir="rtl"] .main_with_right {
  margin-right: 20px;
  margin-left: 0;
}

.video_less_wrap.main_with_left + .right_box_block > .right_box {
  margin-bottom: 20px;
}
.right_box_block,
.left_box_block {
  display: flex;
  flex-direction: column;
  width: calc(30% - 20px);
}

.right_box > div,
.left_box > div {
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

body[dir="rtl"] .right_box > div,
body[dir="rtl"] .left_box > div {
  text-align: right;
}

.date_block {
  background-color: #ffcc36;
  padding: 5px 10px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  color: #000;
  display: inline-block;
}

.btn {
  font-weight: 300;
  display: inline-block;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
  color: #fff;
  border: none;
}

.w-100 {
  width: 100%;
}

button {
  outline: none;
}

.button_green {
  border: 1px solid #94c93d;
  background: #94c93d;
}

.button_green:hover {
  background: #84b433;
}

.button_grayish_blue {
  font-weight: normal;
  border: 1px solid #768699;
  background: #768699;
}

.button_grayish_blue:hover {
  color: #fff;
  border: 1px solid #54657a;
  background: #54657a;
}
.btnActive {
  color: #fff;
  border: 1px solid #54657a;
  background: #54657a;
}
.padding {
  margin: 10px;
}

.btn_lg {
  font-size: 16px;
  line-height: 50px;
  min-width: 240px;
  height: 50px;
  padding: 0 30px;
}

.btn_md {
  font-size: 14px;
  line-height: 40px;
  min-width: 150px;
  height: 40px;
  padding: 0 20px;
}

.btn_sm {
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  padding: 0 20px;
}
.btn_100 {
  font-size: 14px;
  line-height: 40px;
  min-width: 100px;
  height: 40px;
  padding: 0 20px;
}

.emailSummary {
  margin-bottom: 40px;
}

/*------------------------------ FOREX BROKERS RATING ------------------------------*/
.forex_rating_wrap .bg_wrap {
  margin-bottom: 10px;
}

.forex_rating_wrap .bg_wrap:last-child {
  margin-bottom: 0;
}
/*------------------------------ END FOREX BROKERS RATING ------------------------------*/

/*------------------------------ MODAL ------------------------------*/
body .styles_modal__gNwvD {
  max-width: 600px;
  padding: 40px 50px 45px;
  margin: auto;
}

body .styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 450px) {
  body .styles_modal__gNwvD {
    padding: 35px;
  }
}

/*------------------------------ MODAL END ------------------------------*/

/*Review added successfully*/

#register {
  position: relative;
}

.ok_wrap {
  padding-top: 50%;
  display: inline-block;
  width: 100%;
}

.txt_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  height: 40px;
  background: #fff;
  margin-bottom: 15px;
  position: relative;
  animation: txt 3s 1;
}

@keyframes txt {
  0% {
    margin-bottom: -40px;
  }
  30% {
    letter-spacing: 1px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 0px;
    margin-bottom: -40px;
  }
}

/*END Review added successfully*/

@media screen and (max-width: 1365px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: #fff;
  }
  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background: #cccccc;
    height: 8px;
  }
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
}
body[dir="rtl"] .col-1,
body[dir="rtl"] .col-2,
body[dir="rtl"] .col-3,
body[dir="rtl"] .col-4,
body[dir="rtl"] .col-5,
body[dir="rtl"] .col-6,
body[dir="rtl"] .col-7,
body[dir="rtl"] .col-8,
body[dir="rtl"] .col-9,
body[dir="rtl"] .col-10,
body[dir="rtl"] .col-11,
body[dir="rtl"] .col-12 {
  float: right;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  body[dir="rtl"] .col-sm-1,
  body[dir="rtl"] .col-sm-2,
  body[dir="rtl"] .col-sm-3,
  body[dir="rtl"] .col-sm-4,
  body[dir="rtl"] .col-sm-5,
  body[dir="rtl"] .col-sm-6,
  body[dir="rtl"] .col-sm-7,
  body[dir="rtl"] .col-sm-8,
  body[dir="rtl"] .col-sm-9,
  body[dir="rtl"] .col-sm-10,
  body[dir="rtl"] .col-sm-11,
  body[dir="rtl"] .col-sm-12 {
    float: right;
  }
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  body[dir="rtl"] .col-md-1,
  body[dir="rtl"] .col-md-2,
  body[dir="rtl"] .col-md-3,
  body[dir="rtl"] .col-md-4,
  body[dir="rtl"].col-md-5,
  body[dir="rtl"] .col-md-6,
  body[dir="rtl"] .col-md-7,
  body[dir="rtl"] .col-md-8,
  body[dir="rtl"] .col-md-9,
  body[dir="rtl"] .col-md-10,
  body[dir="rtl"] .col-md-11,
  body[dir="rtl"] .col-md-12 {
    float: right;
  }
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  body[dir="rtl"] .col-lg-1,
  body[dir="rtl"] .col-lg-2,
  body[dir="rtl"] .col-lg-3,
  body[dir="rtl"] .col-lg-4,
  body[dir="rtl"] .col-lg-5,
  body[dir="rtl"] .col-lg-6,
  body[dir="rtl"] .col-lg-7,
  body[dir="rtl"] .col-lg-8,
  body[dir="rtl"] .col-lg-9,
  body[dir="rtl"] .col-lg-10,
  body[dir="rtl"] .col-lg-11,
  body[dir="rtl"] .col-lg-12 {
    float: right;
  }
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.no-padding {
  padding: 0;
}

.arrow_up {
  transition: 0.4s;
  margin-left: 10px;
  position: relative;
  top: 2px;
  padding: 3px;
  vertical-align: 2px;
  border: solid #1d1d1d;
  border-width: 0 1px 1px 0;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transition: 0.4s;
}
.arrow_down {
  vertical-align: 2px;
  border: solid #1d1d1d;
  border-width: 0 1px 1px 0;
  margin-left: 10px;
  position: relative;
  top: -2px;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

body[dir="rtl"] .arrow_up,
body[dir="rtl"] .arrow_down {
  margin-right: 10px;
  margin-left: 0;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li.disabled {
  display: none;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #68ac1d;
  border-color: #68ac1d;
  cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #765e7e;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-right: 5px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*---------- Analytics table ----------*/
.tech_table table {
  min-width: 260px;
  margin-bottom: 0;
}

.tech_table table tr:nth-child(even) > td {
  background: #f1f1f1;
}

.tech_table table tr > td {
  border-right: 1px solid #ccc;
}

body[dir="rtl"] .tech_table table tr > td {
  border-left: 1px solid #ccc;
  border-right: none;
}

.tech_table table tr > td:last-child {
  border-right: none;
}

body[dir="rtl"] .tech_table table tr > td:last-child {
  border-left: none;
}

.tech_table table tr {
  border: none;
}

.tech_table table tr:first-child {
  border-top: 1px solid #ccc;
}

.tech_table table tr:last-child {
  border-bottom: 1px solid #ccc;
}

.tech_table table > tr > td {
  font-weight: 300;
  padding: 6px 12px;
}

.tech_table tr td {
  color: #000;
  line-height: 1.71;
}

.tech_table tr td:first-child {
  font-weight: 500;
}

.tech_table table tr td:last-child {
  text-align: left;
}

body[dir="rtl"] .tech_table table tr td:last-child {
  text-align: right;
}
/*---------- End Analytics table ----------*/

/*---------------------------------------- market_overwiev ----------------------------------------*/
.m_overwiev table {
  border: none !important;
}
.m_overwiev table tr:first-child {
  border-top: 1px solid #ccc;
}
/*---------------------------------------- END m_overwiev ----------------------------------------*/

@media (max-width: 1160px) {
  .main_with_left.market_overview_videos,
  .main_with_left.video_less_wrap,
  .main_with_left.video_tutorial_cont,
  .main_with_left.cfd_books_cont,
  .main_with_left.tech_analysis,
  .main_with_left.forex_company,
  .main_with_left.about_us_wrap,
  .main_with_left.innov_block,
  .main_with_left.trading_plt,
  .main_with_left.comments,
  .main_with_left.articles,
  .main_with_left.see_also,
  .main_with_left.curr_p_wrap {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  body[dir="rtl"] .main_with_left.market_overview_videos,
  body[dir="rtl"] .main_with_left.video_tutorial_cont,
  body[dir="rtl"] .main_with_left.video_less_wrap,
  body[dir="rtl"] .main_with_left.cfd_books_cont,
  body[dir="rtl"] .main_with_left.tech_analysis,
  body[dir="rtl"] .main_with_left.forex_company,
  body[dir="rtl"] .main_with_left.about_us_wrap,
  body[dir="rtl"] .main_with_left.innov_block,
  body[dir="rtl"] .main_with_left.trading_plt,
  body[dir="rtl"] .main_with_left.articles,
  body[dir="rtl"] .main_with_left.comments,
  body[dir="rtl"] .main_with_left.see_also,
  body[dir="rtl"] .main_with_left.curr_p_wrap {
    margin-left: 0;
  }

  .main_with_left.market_overview_videos + div,
  .main_with_left.video_tutorial_cont + div,
  .video_less_wrap.main_with_left + div,
  .main_with_left.cfd_books_cont + div,
  .main_with_left.forex_company + div,
  .main_with_left.about_us_wrap + div,
  .main_with_left.comments + div,
  .main_with_left.tech_analysis + div,
  .trading_plt.main_with_left + div,
  .main_with_left.innov_block + div,
  .main_with_left.articles + div,
  .main_with_left.see_also + div,
  .main_with_left.curr_p_wrap + div {
    width: 100%;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/roboto/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/roboto/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/*montserrat*/
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(../fonts/montserrat/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(../fonts/montserrat/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(../fonts/montserrat/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(../fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(../fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* FuroreRegular */
@font-face {
  font-family: "FuroreRegular";
  src: url("../fonts/FuroreRegular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
input,
textarea {
  outline: none;
}

.marketOverviewRss {
  display: flex;
  flex-direction: column;
}
.new_cont_block img {
  width: 450px;
  height: auto;
  padding: 20px 0 20px;
}

.video_less_title + .new_cont_block p:first-of-type img {
  padding-top: 0;
}
