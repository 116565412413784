.video_date_block,
.see_all_video_wrap {
  position: relative;
}

.video_date_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.see_all_video_wrap img {
  cursor: pointer;
  display: flex;
}

.video_news_title {
  display: flex;
  text-align: left;
  color: #000000;
  font-weight: normal;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 38px;
}

body[dir="rtl"] .video_news_title {
  text-align: right;
}

.see_all_date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 20px;
}

.link_color {
  color: #549ae6;
}

@media (max-width: 992px) {
  .see_all_video_wrap {
    display: flex;
  }

  .video_date_block {
    margin-right: 20px;
  }

  body[dir="rtl"] .video_date_block {
    margin-left: 20px;
    margin-right: 0;
  }

  .text_data_bl {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }

  .video_news_title,
  .video_news_title {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .see_all_video_wrap {
    flex-direction: column;
  }

  .video_date_block {
    margin-right: 0;
  }

  body[dir="rtl"] .video_date_block {
    margin-left: 0;
  }

  .video_news_title {
    margin-top: 16px;
    margin-bottom: 38px;
  }
}
