.brokers_container table tr td:last-child {
  text-align: right;
  padding: 0;
}

body[dir="rtl"] .brokers_container table tr td:last-child {
  text-align: left;
}

.number_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.number_img > img {
  margin: 0 25px;
}

.brokersList .btn {
  border-radius: 2px;
}

.brokersList {
  font-weight: 500;
}
.brokersList > td:nth-child(4),
.brokersList > td:nth-child(5),
.brokersList > td:nth-child(6) {
  color: #000;
}

.RatingCustom th,
.brokersList > td:nth-child(4),
.brokersList > td:nth-child(5),
.brokersList > td:nth-child(6) {
  text-align: right;
}

body[dir="rtl"] .RatingCustom th,
body[dir="rtl"] .brokersList > td:nth-child(4),
body[dir="rtl"] .brokersList > td:nth-child(5),
body[dir="rtl"] .brokersList > td:nth-child(6) {
  text-align: left;
}

.brokers_container .right_arrow {
  margin-left: 5px;
}

body[dir="rtl"] .brokers_container .right_arrow {
  margin-right: 5px;
  margin-left: 0;
}

.brokerImage {
  height: 50px;
  width: 100px;
  display: flex;
}

.block_rating {
  display: flex;
  align-items: center;
}

.block_rating_mobile {
  display: none;
}

.block_rating > div {
  margin-right: 15px;
  line-height: 1;
}

body[dir="rtl"] .block_rating > div {
  margin-left: 15px;
  margin-right: 0;
}

.block_rating > span {
  font-size: 14px;
  font-weight: normal;
  color: #717171;
}

.alpari {
  background: url(../../images/brokers/alpari.png) center no-repeat;
}
.avatrader {
  background: url(../../images/brokers/avatrader.png) center no-repeat;
}
.dukascopy {
  background: url(../../images/brokers/dukascopy.png) center no-repeat;
}
.etoro {
  background: url(../../images/brokers/etoro.png) center no-repeat;
}
.exness {
  background: url(../../images/brokers/exness.png) center no-repeat;
}
.forex-com {
  background: url(../../images/brokers/forex-com.png) center no-repeat;
}
.fxpro {
  background: url(../../images/brokers/fxpro.png) center no-repeat;
}
.fxtm {
  background: url(../../images/brokers/fxtm.png) center no-repeat;
}
.hot-forex {
  background: url(../../images/brokers/hot-forex.png) center no-repeat;
}
.ifc-markets {
  background: url(../../images/brokers/ifc-markets.png) center no-repeat;
}
.ig {
  background: url(../../images/brokers/ig.png) center no-repeat;
}
.instaforex {
  background: url(../../images/brokers/instaforex.png) center no-repeat;
}
.interactive-broker {
  background: url(../../images/brokers/interactive-broker.png) center no-repeat;
}
.markets-com {
  background: url(../../images/brokers/markets-com.png) center no-repeat;
}
.oanda {
  background: url(../../images/brokers/oanda.png) center no-repeat;
}
.plus500 {
  background: url(../../images/brokers/plus500.png) center no-repeat;
}
.roboforex {
  background: url(../../images/brokers/roboforex.png) center no-repeat;
}
.saxo {
  background: url(../../images/brokers/saxo.png) center no-repeat;
}
.swissquote {
  background: url(../../images/brokers/swissquote.png) center no-repeat;
}
.xm {
  background: url(../../images/brokers/xm.png) center no-repeat;
}

@media screen and (max-width: 992px) {
  .brokers_rating .bg_wrap table {
    min-width: 910px;
  }
  .brokers_rating .brokers_container .bg_wrap > div {
    overflow: auto;
  }
}

@media (max-width: 978px) {
  .brokers_container .swipe {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 340px) {
  .block_rating {
    display: none;
  }

  .block_rating_mobile {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  body[dir="rtl"] .block_rating_mobile {
    margin-left: 20px;
    margin-right: 0;
  }

  .block_rating_mobile > div {
    margin-right: 15px;
    line-height: 1;
  }

  body[dir="rtl"] .block_rating_mobile > div {
    margin-left: 15px;
    margin-right: 0;
  }
}
