.expert_corner {
  height: 100%;
}

.expert_corner {
  background: #fff;
  height: 100%;
  padding: 20px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.expert_block {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9e3f0;
}

.bg_wrap > .expert_block {
  margin-bottom: 20px;
}

.expert_block {
  display: flex;
  flex-wrap: wrap;
}

.expert_block:last-child {
  margin-bottom: 0;
}

.expert_block img {
  border-radius: 50%;
  height: 50px;
}

.expert_right_block {
  width: calc(100% - 80px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}

body[dir="rtl"] .expert_right_block {
  padding-right: 20px;
  padding-left: 0;
}

.expert_title {
  line-height: 1.43;
}

.expert_date_bl {
  font-weight: normal;
}

@media (max-width: 992px) {
  .right_box_block {
    width: 100%;
  }
}
