.dropbtn {
  background-color: #1a1a1a;
  color: #dddddd;
  padding: 15px;
  font-size: 14px;
  border: none;
  font-family: Roboto;
}
.dropbtn:hover {
  background-color: #1a1a1a;
  color: white;
  padding: 15px 15px 12px 15px;
  font-size: 14px;
  /*border: none;*/
  border-bottom: 3px solid #68ac1d;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a1a1a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #ddd;
  height: 40px;
  text-decoration: none;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px !important;
  text-align: left !important;
}

.dropdown-content a:hover {
  background-color: #494949;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #494949;
}
