.txt_container {
  margin-bottom: 30px;
  width: 100%;
}

.txt_container h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.prof_reg_private {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
  color: #fff;
  background-color: #2d2d2d;
  align-items: center;
}

.prof_reg_private ul {
  font-family: FuroreRegular;
  font-size: 14px;
  list-style: none;
}

.ul_num {
  position: relative;
  display: inline-block;
  float: left;
  width: 25%;
  min-width: 270px;
}

body[dir="rtl"] .ul_num {
  float: right;
}

.ul_num li {
  line-height: 19px;
  text-align: left;
}

body[dir="rtl"] .ul_num li {
  text-align: right;
}

.li_num {
  display: inline-block;
  width: 20px;
  padding-right: 10px;
}

body[dir="rtl"] .li_num {
  padding-left: 10px;
  padding-right: 0;
}

.system {
  position: relative;
  display: inline-block;
  float: left;
  min-width: 45px;
  height: 152px;
  background-repeat: no-repeat;
}

body[dir="rtl"] .system {
  float: right;
}

body[dir="rtl"] .system img {
  transform: rotate(180deg);
}

.infinity {
  width: 46px;
  min-width: 46px;
  height: 23px;
}

.inlimmited_right_part {
  display: inline-block;
  padding-left: 30px;
  text-align: left;
}

body[dir="rtl"] .inlimmited_right_part {
  padding-right: 30px;
  padding-left: 0;
  text-align: right;
}

.point {
  padding: 12px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  display: flex;
}

body[dir="rtl"] .point {
  border-radius: 5px 0 0 5px;
}

.point span {
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 50%;
  margin-right: 8px;
}

body[dir="rtl"] .point span {
  margin-left: 8px;
  margin-right: 0;
}

.point .red {
  background: #fd5650;
}

.point .orange {
  background: #fbb03c;
}

.point .green {
  background: #8cc640;
}

.adv_wcont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.adv_wcont > div {
  width: 48%;
  border-left: 2px solid #94c93d;
  padding-left: 20px;
  margin-bottom: 30px;
  line-height: 24px;
  text-align: left;
}

body[dir="rtl"] .adv_wcont > div {
  border-right: 2px solid #94c93d;
  border-left: none;
  padding-right: 20px;
  padding-left: 0;
  text-align: right;
}

.adv_wcont > div + div + div,
.adv_wcont > div:last-child {
  margin-bottom: 0;
}

.main_with_left.innov_block + .right_box_block .education_section {
  padding-top: 0;
}

@media (max-width: 1269px) {
  .edu_sec_blk .right_arrow {
    display: none;
  }
}

@media (max-width: 1160px) {
  .edu_sec_blk .right_arrow {
    display: block;
  }

  .right_box_block .education_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .right_box_block .edu_sec {
    width: calc(50% - 5px);
  }
}

@media (max-width: 735px) {
  .inlimmited_right_part {
    width: 100%;
    padding: 0;
    text-align: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #fff;
  }
}

@media (max-width: 690px) {
  .right_box_block .edu_sec {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .innovation .adv_wcont > div {
    width: 100%;
  }

  .system,
  .infinity {
    display: none;
  }

  .prof_reg_private .ul_num {
    margin: 0 auto;
  }

  .ul_num {
    width: auto;
    min-width: auto;
  }
}

@media (max-width: 360px) {
  .edu_sec_blk .right_arrow {
    display: none;
  }

  .edu_sec .left_part {
    font-size: 14px;
    margin-right: 0;
  }

  body[dir="rtl"] .edu_sec .left_part {
    margin-left: 0;
  }
}
