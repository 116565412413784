.broker_list {
  overflow-x: auto;
  overflow-y: hidden;
}

.broker_list tr.brokersList td:last-child {
  text-align: right;
  padding: 10px 15px;
}

.swipe {
  display: none;
}

.broker_list_wrap + div {
  display: flex;
  flex-wrap: wrap;
  box-shadow: -6px 3px 8px rgba(0, 0, 0, 0.2);
}

.broker_list_wrap {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #d8d8d8;
  box-shadow: inset 0 10px 6px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 10px 6px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 10px 6px -5px rgba(0, 0, 0, 0.1);
}

.broker_list_info {
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: normal;
  color: #000;
  margin-bottom: 15px;
}

.broker_list_info > div:nth-child(2) {
  margin: 0 28px;
}

.info_min {
  font-size: 12px;
  font-weight: normal;
  color: #717171;
}

.broker_list_mobile {
  border-bottom: 1px solid #d8d8d8;
}

.broker_list_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 0;
}

body[dir="rtl"] .broker_list_top {
  padding: 10px 0px 10px 10px;
}

.broker_list_mobile .button_grayish_blue {
  border-radius: 2px;
  border: solid 1px #768699;
}

.list_button.button_grayish_blue {
  width: 25px;
  height: 25px;
  position: relative;
}

.list_button > div {
  border: solid #fff;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: calc(50% - 5px);
  left: -2px;
}

body[dir="rtl"] .list_button > div {
  right: -2px;
  left: auto;
}

.list_button > .arrow_up {
  top: calc(50% - 1px);
}

.broker_list_mobile {
  display: none;
}

.sort_by.mobile ul {
  justify-content: space-between;
}

.sort_by.mobile ul li {
  margin-right: 0;
}

body[dir="rtl"] .sort_by.mobile ul li {
  margin-left: 0;
}

.sort_by.mobile {
  display: none;
}

.brok_txt {
  margin-bottom: 25px;
}

@media (max-width: 1240px) and (min-width: 661px) {
  .main_with_right .sort_by + .swipe {
    display: block;
    margin: 20px 0 10px;
    text-align: center;
  }

  .main_with_right .broker_list table {
    min-width: 950px;
  }
}

@media (max-width: 660px) {
  .main_with_right .brokersList,
  .main_with_right .broker_list .RatingCustom {
    display: none;
  }

  .main_with_right .broker_list_mobile {
    display: block;
  }

  .sort_by {
    display: none;
  }

  .sort_by.mobile {
    display: block;
  }
}
