.chart_wrapper_block {
  display: inline-block;
  width: calc(70% - 21px);
  padding-right: 20px;
  border-right: 1px solid #d8d8d8;
  margin-right: 20px;
}

body[dir="rtl"] .chart_wrapper_block {
  border-left: 1px solid #d8d8d8;
  border-right: none;
  margin-left: 20px;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 0;
}

.char_wrapper {
  height: 490px;
  margin-bottom: 20px;
}

.chart_btm {
  align-items: center;
}

.chart_btm a {
  margin-right: 20px;
}

body[dir="rtl"] .chart_btm a {
  margin-left: 20px;
  margin-right: 0;
}

.category_group {
  display: inline-block;
  min-width: 185px;
}

.category_group:first-child {
  margin-right: 10px;
}

body[dir="rtl"] .category_group:first-child {
  margin-left: 10px;
  margin-right: 0;
}

.chart_top {
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: center;
}

.chart_top h2 {
  font-weight: normal;
  width: auto;
  margin: 0;
}

.category_group > div > div {
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.category_group > div > div > div > div:first-child {
  color: #000;
  font-weight: normal;
}

.category_group > div > div > div:last-child > span {
  display: none;
}

.percent_cont {
  padding: 15px;
  color: #fff;
  background: #1a1a1a;
}

.percent_block {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.percent_title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
}

.current_instrument_bid {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: normal;
  margin-right: 10px;
  color: #fff;
}

body[dir="rtl"] .current_instrument_bid {
  margin-left: 10px;
  margin-right: 0;
}

.change_with_percent_up {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  color: #7bab2e;
}
.change_with_percent_down {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  color: red;
}

.tableItemStatus {
  color: black;
}
.tableItemStatusup {
  color: #7bab2e;
}
.tableItemStatusdown {
  color: red;
}

.top_desc {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  min-height: 170px;
  background: #d8d8d8;
}

.subheadline_block {
  margin-bottom: 30px;
}

.subheadline_block h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 0px;
}

body[dir="rtl"] .subheadline_block h4 {
  text-align: right;
}

.curr_p_wrap h2 {
  font-weight: normal;
}

.txt_bl_curr {
  margin-bottom: 30px;
}

.txt_bl_curr.curr_p_block {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.txt_bl_curr > div {
  width: calc(50% - 20px);
}

.txt_bl_curr > .one_column {
  width: 100%;
}

.intervalButton {
  display: flex;
  justify-content: flex-end;
}

.Apper > button {
  border-radius: 5px;
  margin: 5px;
  width: 25px;
  height: 25px;
}

.Apper > div {
  height: 40px;
}

.Apper > .intervalButton > button:hover {
  color: #fff;
  background-color: #3d77bb;
}

.Apper > .intervalButton > button {
  height: 30px;
  width: 30px;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  color: #3d77bb;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.chart_active_period {
  background-color: #3d77bb !important;
  color: #fff !important;
}

button,
input,
select,
textarea {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.category_group > div > div {
  display: flex;
}

.category_group > div > div + div {
  border: none;
  box-shadow: none;
}

.cur_history table td {
  color: #000;
  font-weight: normal;
}

@media (max-width: 1070px) {
  .chart_wrapper_block {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 30px;
    border-right: none;
  }

  .right_box_block.block_val {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .live_chart .swipe {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .cur_history {
    overflow: auto;
  }

  .cur_history table {
    min-width: 910px;
  }
}

@media (max-width: 550px) {
  .percent_cont {
    margin-bottom: 20px;
  }

  .txt_bl_curr.curr_p_block {
    flex-direction: column;
  }
  .txt_bl_curr > div {
    width: 100%;
  }

  .chart_top h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .category_group {
    min-width: 100%;
  }
  .category_group:first-child {
    margin-right: 0;
  }
  .category_group > div > div,
  .category_block {
    width: 100%;
  }
  .Apper > .intervalButton > button {
    height: 22px;
    width: 22px;
    margin: 5px;
    padding: 0;
    font-size: 12px;
  }
}
