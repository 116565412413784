.filter_wrap .category_block {
  justify-content: space-between;
}

.category_block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter_wrap {
  border-bottom: 1px solid #f1f1f1;
}

.search_filter_form_row {
  width: calc(33.4% - 44px);
  margin-right: 9px;
}

body[dir="rtl"] .search_filter_form_row {
  margin-left: 9px;
  margin-right: 0;
}

.search_filter_form_row > div > div {
  border: 1px solid #ccc;
  border-radius: 0;
}

.search_filter_form_row > div > div > div > div:first-child {
  color: #9b9b9b;
  font-family: Montserrat;
}

.search_filter_form_row > div > div > div:last-child > span {
  display: none;
}

.tech_wrap {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
}

.tech_wrap:last-of-type {
  margin-bottom: 20px;
}

.tech_top {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.curr_p_wrap .live_chart h2 {
  font-weight: bold;
}

.tech_top h3 {
  margin-bottom: 0;
  text-align: left;
}

body[dir="rtl"] .tech_top h3 {
  text-align: right;
}

.tech_cont {
  display: flex;
  justify-content: space-between;
}

.analysis_left_part {
  display: flex;
  padding-right: 50px;
  flex-wrap: wrap;
  align-items: flex-start;
}

body[dir="rtl"] .analysis_left_part {
  padding-left: 50px;
  padding-right: 0;
}

.analysis_left_part {
  text-align: left;
}

body[dir="rtl"] .analysis_left_part {
  text-align: right;
}

.author_regarding {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.img_author {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  background: red;
}

body[dir="rtl"] .img_author {
  margin-left: 10px;
  margin-right: 0;
}
.tech_analysis .expert_right_block div {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
}

.tech_analysis .expert_right_block div:first-child {
  color: #4a4a4a;
}

.tech_analysis .expert_right_block div:last-child {
  color: #000;
}

.analysis_left_part > a {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  text-decoration: underline;
  color: #4a4a4a;
}

.next_analysis {
  display: inline-block;
  margin-bottom: 30px;
  text-decoration: underline;
}

.btm_txt {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  line-height: 1.67;
  color: #9b9b9b;
}

.tech_table tr td {
  color: #000;
}

.tech_table tr td:first-child {
  font-weight: 500;
  line-height: 1.71;
}

.bg_wrap .tech_table table tr td:last-child {
  text-align: left;
}

body[dir="rtl"] .bg_wrap .tech_table table tr td:last-child {
  text-align: right;
}

.analysis_left_part .author_regarding {
  display: none;
}

/*---------------------------------------- market_overwiev ----------------------------------------*/
.m_overwiev table {
  border: none !important;
}
.m_overwiev table tr:first-child {
  border-top: 1px solid #ccc;
}
/*---------------------------------------- END m_overwiev ----------------------------------------*/
@media (max-width: 720px) {
  .books_blk_footer {
    flex-direction: column;
  }
  .books_left_part {
    width: 100%;
    margin-bottom: 20px;
  }
  .regarding_block > div,
  .regarding_block .author_regarding {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }

  .cfd_books_cont .img_author {
    display: none;
  }
  .cfd_books_cont .block_author,
  .book_regarding,
  .book_regarding:last-child {
    height: 25px;
    border-bottom: 1px dashed #ccc;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .book_regarding > div,
  .cfd_books_cont .block_author div {
    position: absolute;
    background: #fff;
    top: 12px;
    padding: 0 3px;
  }
  .book_regarding > div:first-child,
  .cfd_books_cont .block_author > div:first-child {
    left: 0;
  }
  .book_regarding div:last-child,
  .cfd_books_cont .block_author > div:last-child {
    right: 0;
  }
}

@media (max-width: 600px) {
  .search_filter_form_row {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  body[dir="rtl"] .search_filter_form_row {
    margin-left: 0;
  }

  .tech_top,
  .tech_cont {
    display: block;
  }

  .tech_top h3 {
    margin-bottom: 10px;
  }

  .analysis_left_part {
    margin-bottom: 10px;
    padding-right: 0;
  }

  body[dir="rtl"] .analysis_left_part {
    padding-left: 0;
  }

  .analysis_left_part > div:first-child {
    margin-bottom: 10px;
  }
}
/*---------------------------------------- END m_overwiev ----------------------------------------*/

/*you may add the styles bellow  that need to be similar to your styling (as you wish) */
.block_val table.chart-table tr th:nth-child(2),
.block_val table.chart-table tr td:nth-child(2) {
  width: initial;
  text-align: center;
}
.block_val table.chart-table tr th:nth-child(3),
.block_val table.chart-table tr td:nth-child(3) {
  width: initial;
}
