@media (min-width: 320px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1440px;
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.content .container:last-child {
  margin-bottom: 0;
}

.content {
  padding-top: 30px;
  padding-bottom: 50px;
  background: #f1f1f1;
}

.bg_wrap,
.txt_wrap {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.date_block {
  background-color: #ffcc36;
  padding: 5px 10px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  color: #000;
  display: inline-block;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main_with_left {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 20px;
}

.right_box_block,
.left_box_block {
  display: flex;
  flex-direction: column;
  width: calc(30% - 20px);
}

.right_box > div,
.left_box > div {
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.expel_class .right_box_block {
  width: 100%;
}

.expel_class.top_5 {
  margin-bottom: 10px;
}

.expel_class {
  margin-bottom: 20px;
}

.expel_class:last-child {
  margin-bottom: 0;
}

.yout_video_icn {
  position: absolute;
  width: 40px;
  padding: 8px 0;
  border-radius: 7px;
  background: red;
  top: calc(50% - 15px);
}

.yout_video_icn:hover {
  opacity: 0.5;
  cursor: pointer;
}

.yout_video_icn div {
  width: 0;
  height: 0;
  margin: 0 10px 0 16px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 7px 0 7px 10px;
}

.loader_block {
  text-align: center;
}

h1.loader {
  text-align: center;
  margin: 45px 0;
  color: #4a4a4a;
  width: 145px;
  display: flex;
  font-size: 30px;
  font-style: italic;
}

h1.loader::after {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  -webkit-animation: ellipsis steps(4, end) 1s infinite;
  animation: ellipsis steps(5, end) 1s infinite;
  content: "\2026";
  width: 55px;
  margin-right: 35px;
}

.btn {
  font-weight: 300;
  display: inline-block;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
  color: #fff;
  border: none;
}

.btn_md {
  font-size: 14px;
  line-height: 40px;
  min-width: 150px;
  height: 40px;
  padding: 0 20px;
}

.btn_sm {
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  padding: 0 20px;
}

.btn_100 {
  font-size: 14px;
  line-height: 40px;
  min-width: 100px;
  height: 40px;
  padding: 0 20px;
}

button {
  outline: none;
}

.button_green {
  border: 1px solid #94c93d;
  background: #94c93d;
}

.button_green:hover {
  color: #fff !important;
  background: #84b433;
  border: 1px solid #84b433;
}

.button_grayish_blue {
  font-weight: normal;
  border: 1px solid #768699;
  background: #768699;
}

.button_grayish_blue:hover {
  color: #fff;
  border: 1px solid #54657a;
  background: #54657a;
}

.btn_lg {
  font-size: 16px;
  line-height: 50px;
  min-width: 240px;
  height: 50px;
  padding: 0 30px;
}

.btm_txt {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  line-height: 1.67;
  color: #9b9b9b;
}

.main_with_left h1{
  padding-bottom: 0;
}

h2,
.main_with_right h1,
.main_with_left h1,
.widthcalendarTab h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: 0 0 15px;
  text-align: left;
  text-transform: uppercase;
  color: #000;
}

.loader {
  text-align: center;
  margin: 45px 0;
  color: #4a4a4a;
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-style: italic;
}

.loader::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1s infinite;
  animation: ellipsis steps(4, end) 1s infinite;
  content: "\2026";
  width: 0;
  margin-right: 10px;
}

@keyframes ellipsis {
  to {
    width: 10px;
    margin-right: 0;
  }
}

@media (max-width: 1160px) {
  .main_with_left {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  body[dir="rtl"] .main_with_left {
    margin-left: 0;
  }

  .main_with_left + div {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .two_column {
    flex-direction: column;
  }

  .main_with_left {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .right_box_block {
    width: 100%;
  }
}
