.category_block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter_wrap {
  border-bottom: 1px solid #f1f1f1;
}

.search_filter_form_row {
  width: calc(33.4% - 44px);
  margin-right: 9px;
}

body[dir="rtl"] .search_filter_form_row {
  margin-left: 9px;
  margin-right: 0;
}

.search_filter_form_row > div > div {
  border-radius: 0;
}

.search_filter_form_row > div > div:first-child {
  min-height: 40px;
}

.search_filter_form_row > div > span + div:hover {
  border-color: hsl(0, 0%, 80%);
}
.search_filter_form_row > div > span + div {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  min-height: 40px;
}

.search_filter_form_row > div > div > div > div:first-child {
  font-family: Montserrat;
}

.search_filter_form_row > div > div > div:last-child > span {
  display: none;
}

.tech_top {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tech_top h3 {
  margin-bottom: 0;
  text-align: left;
}

body[dir="rtl"] .tech_top h3 {
  text-align: right;
}

.tech_cont {
  display: flex;
  justify-content: space-between;
}

.analysis_left_part {
  display: flex;
  padding-right: 50px;
  flex-wrap: wrap;
  align-items: flex-start;
}

body[dir="rtl"] .analysis_left_part {
  padding-left: 50px;
  padding-right: 0;
}

.analysis_left_part {
  text-align: left;
}

body[dir="rtl"] .analysis_left_part {
  text-align: right;
}

.analysis_left_part > a {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  text-decoration: underline;
  color: #4a4a4a;
}

.analysis_left_part .author_regarding {
  display: none;
}

.author_regarding {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.expert_block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.expert_block:last-child {
  margin-bottom: 0;
}

.expert_block img {
  border-radius: 50%;
}

.tech_analysis .expert_right_block div {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
}

.tech_analysis .expert_right_block div:first-child {
  color: #4a4a4a;
}

.tech_analysis .expert_right_block div:last-child {
  color: #000;
}

.new_cont_block {
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.6;
}
.tech_analysis_img img {
  height: 190px;
  width: auto;
  float: left;
  padding: 0 20px 20px 0;
}

body[dir="rtl"] .new_cont_block {
  text-align: right;
}

.tech_wrap {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
}

.tech_wrap:last-of-type {
  margin-bottom: 20px;
}

.expert_right_block {
  width: calc(100% - 80px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}

body[dir="rtl"] .expert_right_block {
  padding-right: 20px;
  padding-left: 0;
}

.next_analysis {
  display: inline-block;
  margin-bottom: 30px;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .search_filter_form_row {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  body[dir="rtl"] .search_filter_form_row {
    margin-left: 0;
  }

  .analysis_left_part {
    margin-bottom: 10px;
    padding-right: 0;
  }

  body[dir="rtl"] .analysis_left_part {
    padding-left: 0;
  }

  .analysis_left_part > div:first-child {
    margin-bottom: 10px;
  }

  .tech_top,
  .tech_cont {
    display: block;
  }

  .tech_top h3 {
    margin-bottom: 10px;
  }
}
