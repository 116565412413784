.ninja_top {
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.plt_option span {
  font-size: 13px;
  font-weight: normal;
  margin-right: 10px;
  padding: 5px 15px;
  display: inline-block;
}

body[dir="rtl"] .plt_option span {
  margin-left: 10px;
  margin-right: 0;
}
.platform_text_cont {
  line-height: 24px;
}
.plt_option {
  margin-bottom: 20px;
}

.light_orange {
  border-radius: 3px;
  background-color: #ffcc36;
}

.light_gray {
  border-radius: 3px;
  background-color: #f1f1f1;
}

.platform_text_cont > div {
  margin-bottom: 40px;
}

.platform_text_cont > div:last-child {
  margin-bottom: 0;
}

.text_see_also {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 25px;
  padding-bottom: 20px;
  color: #000;
  border-bottom: 1px solid #d8d8d8;
}

.block_see_also {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d9e3f0;
}

.block_see_also:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.tradingPlatform {
  height: 78px;
  width: 300px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.bg_wrap_list {
  margin-top: 20px;
}
.fixed_height {
  height: 100%;
}

@media (max-width: 635px) {
  .plt_option,
  .light_orange {
    margin-bottom: 10px;
  }
}
@media (max-width: 370px) {
  .block_see_also img {
    width: 100%;
  }
}
