.loader_block {
  text-align: center;
}

h1.loader {
  margin: 15px 0 45px;
  color: #4a4a4a;
  width: 145px;
  display: flex;
  font-size: 30px;
  font-style: italic;
  text-transform: none;
  font-family: Roboto;
}

h1.loader::after {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  -webkit-animation: ellipsis steps(4, end) 1s infinite;
  animation: ellipsis steps(5, end) 1s infinite;
  content: "\2026";
  width: 55px;
  margin-right: 35px;
}

.loader {
  text-align: center;
  margin: 45px 0;
  color: #4a4a4a;
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-style: italic;
}

.loader::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1s infinite;
  animation: ellipsis steps(4, end) 1s infinite;
  content: "\2026";
  width: 0;
  margin-right: 10px;
}

@keyframes ellipsis {
  to {
    width: 10px;
    margin-right: 0;
  }
}

.filter_block {
  margin-bottom: 10px;
}

.filters_list {
  max-height: 120px;
  overflow: auto;
}

.filters_list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: #f1f1f1;
}

.filters_list::-webkit-scrollbar-thumb {
  height: auto;
}

.filters_list label {
  color: #000;
  text-align: left;
}

body[dir="rtl"] .filters_list label {
  text-align: right;
}

label {
  display: flex;
  height: auto;
  padding-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  font-weight: normal;
}

label:last-child {
  margin-bottom: 0;
}

.filter_block input {
  position: relative;
  overflow: visible;
  cursor: pointer;
  opacity: 0;
  display: none;
}

.filters_list input:checked + .checkmark {
  border-color: #94c93d;
  background: #94c93d;
  transition: 0.3s;
}

.filters_list input:checked + .checkmark:after {
  border-color: #fff;
}

.checkmark {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
}

body[dir="rtl"] .checkmark {
  margin-left: 15px;
  margin-right: 0;
}

label input:checked ~ .checkmark:after {
  display: block;
}

label .checkmark:after {
  top: 2px;
  left: 6px;
  width: 4px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid #a2a2a2;
  border-width: 0 2px 2px 0;
}

body[dir="rtl"] .checkmark:after {
  right: 6px;
  left: auto;
}

.checkmark:after {
  position: absolute;
  display: none;
  content: "";
}

/*---------------------------------------- filter language ----------------------------------------*/
.filter_lang_block > form {
  cursor: pointer;
}

.fitler_title {
  font-weight: bold;
  color: #7da740;
  border-bottom: 1px solid #d9e3f0;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.content_hover .left_box_block.left_box {
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  flex-direction: column;
  align-items: flex-end;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.body_overflow_hidden {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.content_hover.content {
  position: relative;
}

.sort_by ul .filter_mobile_li {
  display: none;
}

.anim_filter_block button {
  min-width: 100%;
}

@media (max-width: 900px) {
  .content_hover.content {
    padding-top: 0;
  }
  .left_box_block.left_box,
  .content_hover .left_box_block.left_box h2 {
    display: none;
  }
  .anim_filter_block {
    position: absolute;
    display: block;
    top: 0;
    max-height: 100%;
    overflow: auto;
    width: 100%;
    transition: 0.5s;
  }
  .content_hover .left_box_block.left_box {
    background: #fff;
    position: fixed;
    display: block;
    top: 102px;
    max-height: 100%;
    z-index: 10;
  }

  .content_hover .left_box_block.left_box .btn_lg {
    min-width: 260px;
  }

  .main_with_right {
    width: 100%;
    margin-left: 0;
  }

  body[dir="rtl"] .main_with_right {
    margin-right: 0;
  }
  .left_box > div.filter_block {
    margin-bottom: 0;
    box-shadow: none;
  }

  .filters_list {
    max-height: initial;
  }
  .sort_by ul .filter_mobile_li {
    display: flex;
  }
}

@media (max-width: 900px) and (min-width: 660px) {
  .sort_by ul {
    justify-content: flex-end;
    position: relative;
  }

  .sort_by ul .filter_mobile_li {
    position: absolute;
    left: 10px;
  }

  body[dir="rtl"] .sort_by ul .filter_mobile_li {
    right: 10px;
    left: auto;
  }
}

@media (max-width: 550px) {
  .content_hover .left_box_block.left_box {
    top: 90px;
  }
}
