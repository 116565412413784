.education {
  display: flex;
  width: calc(25% - 15px);
  margin-right: 20px;
  flex-wrap: wrap;
  color: #1a1a1a;
}

body[dir="rtl"] .education {
  margin-left: 20px;
  margin-right: 0;
}

.education_block:hover {
  background: #768699;
  color: #fff;
  transition: 0.4s;
}

.education_block:hover .edu_text {
  color: #fff;
}

.education_block:hover .edu_num {
  background: #fff;
  color: #768699;
}

.education_block {
  font-family: Roboto;
  font-weight: normal;
  position: relative;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 30px;
  background: #fff;
  -webkit-flex-direction: column;
}

.education:last-child {
  margin-right: 0;
}

body[dir="rtl"] .education:last-child {
  margin-left: 0;
}

.edu_text {
  font-size: 18px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #000;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

body[dir="rtl"] .edu_text {
  text-align: right;
}

.edu_txt_bl > p {
  line-height: 1.71;
  margin: 0;
  text-align: left;
}

body[dir="rtl"] .edu_txt_bl > p {
  text-align: right;
}

.edu_num {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  width: 60px;
  height: 70px;
  padding-top: 10px;
  padding-right: 20px;
  text-align: center;
  color: #fff;
  border-top-left-radius: 100%;
  background: #768699;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

body[dir="rtl"] .edu_num {
  left: 0;
  right: auto;
  padding-left: 20px;
  padding-right: 0;
  border-top-right-radius: 100%;
  border-top-left-radius: 0;
}

.video_les {
  display: flex;
  height: 187px;
  margin-bottom: 30px;
  cursor: pointer;
  background: url(/static/media/what_is_forex.8418cb32.jpg) center no-repeat;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

@media (max-width: 1160px) {
  .education {
    width: calc(50% - 15px);
    margin-bottom: 20px;
  }

  .education:nth-child(odd) {
    margin-right: 0;
  }

  body[dir="rtl"] .education:nth-child(odd) {
    margin-left: 0;
  }
}

@media (max-width: 550px) {
  .education,
  body[dir="rtl"] .education {
    width: 100%;
    margin: 0 0 20px;
  }
}
