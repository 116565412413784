.footer .top_menu_wrapper,
.footer .top_menu_wrapper ul,
.footer .top_menu_wrapper ul li,
.footer .top_menu_wrapper ul li a,
.footer .top_menu_wrapper #menu-button {
  justify-content: center;
}

.f_text {
  background: #1a1a1a;
  padding: 30px 0;
  color: #999;
}

.footer .container {
  margin-bottom: 0;
}

.footer .menu,
.footer > div:last-child {
  background: #252525;
}

.footer > div:last-child p {
  padding: 12px;
  text-align: center;
}

.footer p {
  margin: 0;
  font-weight: normal;
  color: #929292;
}

@media (max-width: 992px) {
  .footer .menu {
    display: none;
  }
}
