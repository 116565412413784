.news_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding-right: 30px;
  height: 100%;
}

body[dir="rtl"] .news_wrap {
  padding-left: 30px;
  padding-right: 20px;
}

.news_left_part {
  margin-right: 20px;
  max-width: 500px;
}

body[dir="rtl"] .news_left_part {
  margin-left: 20px;
  margin-right: 0;
}

.news_left_part img {
  width: 100%;
  height: 100%;
}

.news_right_part {
  width: calc(100% - 520px);
}

.news_content {
  position: relative;
  height: 100%;
  text-align: left;
}

body[dir="rtl"] .news_content {
  text-align: right;
}

.news_title {
  font-weight: normal;
}

.date_block {
  position: absolute;
  bottom: 0;
  left: 0;
}

body[dir="rtl"] .date_block {
  right: 0;
  left: auto;
}

.news_content > div:first-of-type {
  overflow: hidden;
  max-height: 105px;
  margin-bottom: 80px;
  line-height: 1.57;
  font-weight: normal;
}

@media (max-width: 767px) {
  .news_left_part {
    margin-bottom: 20px;
    margin-right: 0;
    max-width: initial;
  }

  body[dir="rtl"] .news_left_part {
    margin-left: 0;
  }

  .news_wrap {
    flex-direction: column;
    padding-right: 20px;
  }

  body[dir="rtl"] .news_wrap {
    padding-left: 20px;
    padding-right: 0;
  }

  .news_right_part {
    width: 100%;
  }
}
