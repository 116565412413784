.header .container {
  margin-bottom: 0;
}

.header {
  padding: 10px 0;
}

.header .top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header.container {
  margin-bottom: 0;
}

.header .top {
  justify-content: space-between;
  width: 100%;
}

form.searchform {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

form.searchform button {
  position: absolute;
  right: 10px;
  border: none;
  padding: 0;
  background: inherit;
  transform: rotateY(180deg);
  cursor: pointer;
}

body[dir="rtl"] form.searchform button {
  left: 10px;
  right: auto;
}

form.searchform button .icon-search {
  font-size: 23px;
  font-weight: normal;
  color: #000;
}

.searchform input[type="search"] {
  font-size: 14px;
  position: static;
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-align: left;
  color: #4a4a4a;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-appearance: none;
}

body[dir="rtl"] .searchform input[type="search"] {
  text-align: right;
}

.searchform input[type="search"]:focus {
  outline: none;
}

.lang_menu .flag,
.watch {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.watch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: #fff;
}

.watch {
  position: relative;
}

.watch:before {
  content: "";
  position: absolute;
  right: 10px;
  padding: 2.5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  vertical-align: 2px;
  border: solid #4a4a4a;
  border-width: 0 1px 1px 0;
  z-index: 1;
}

body[dir="rtl"] .watch:before {
  left: 10px;
  right: auto;
}

.menu .watch {
  display: none;
}

.menu .watch input {
  color: #fff;
}

.menu .watch:before,
.menu .flag_arrow {
  border-color: #fff;
}

.right_part .watch input,
.menu .watch input {
  padding: 0 27px 0 0;
  margin: 0;
  border: none;
  width: 105px;
  cursor: pointer;
  background: transparent;
}

body[dir="rtl"] .right_part .watch input,
body[dir="rtl"] .menu .watch input {
  padding: 0 0 0 27px;
}

.watch ul.open {
  background: #fff;
  z-index: 1;
  min-width: 210px;
  top: 33px;
  max-height: 194px;
  border: 1px solid #ccc;
}

.lang_menu .flag > div,
.time_zone {
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  float: left;
  margin-top: 0;
  margin-right: 10px;
  margin-left: 0;
  color: #1d1d1d;
}

body[dir="rtl"] .lang_menu .flag > div,
body[dir="rtl"] .time_zone {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

.lang_menu ul li {
  display: flex;
  align-items: center;
  position: relative;
}

.flag_none {
  position: absolute;
  right: 3px;
  top: 35px;
  width: 560px;
  background: #fff;
  overflow: hidden;
  padding: 10px;
  z-index: 600;
  border: 1px solid #ccc;
}

body[dir="rtl"] .flag_none {
  left: 3px;
  right: auto;
}

.flag_24 {
  display: inline-block;
  width: 28px;
  height: 22px;
  background: url(../../images/flags/flags_sprite.png);
  margin-right: 7px;
  min-width: 28px;
}

body[dir="rtl"] .flag_24 {
  margin-left: 7px;
  margin-right: 0;
}

.flag_arrow {
  width: 6px;
  height: 6px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  pointer-events: none;
  border-top: 1px solid #4a4a4a;
  border-right: 1px solid #4a4a4a;
}

.flag_arrow:hover,
.flag_:hover,
.flag_text:hover {
  cursor: pointer;
}

.deactivate {
  display: none;
}

.flag_block {
  cursor: pointer;
}

.flag_none > div:hover,
.flag_none > div.deactivate {
  background: #cce3ff;
  cursor: pointer;
}

.flag_none > div {
  cursor: pointer;
  color: #666666;
  float: left;
  width: 33.33%;
  box-sizing: border-box;
  padding: 0 5px;
}

body[dir="rtl"] .flag_none > div {
  float: right;
}

.flag_none > div > a {
  color: #666666;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  line-height: 36px;
}

.flag_none .flag_image {
  margin: 0 8px 0 0;
}

body[dir="rtl"] .flag_none .flag_image {
  margin: 0 0 0 8px;
}

.arrow {
  position: relative;
  top: -2px;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  vertical-align: 2px;
  border: solid #1d1d1d;
  border-width: 0 1px 1px 0;
}

.flag_image {
  width: 29px;
  height: 21px;
  margin-right: 10px;
  background: url(../../images/flags/flags_sprite.png) center no-repeat;
  display: inline-block;
}

body[dir="rtl"] .flag_image {
  margin-left: 10px;
  margin-right: 0;
}

/*lang*/
.EN,
.en {
  background-position: -204px 0px;
}
.RU,
.ru {
  background-position: -407px 0;
}
.ES,
.es {
  background-position: -1px -21px;
}
.FR,
.fr {
  background-position: -204px -21px;
}
.DE,
.de {
  background-position: -291px -21px;
}
.IT,
.it {
  background-position: -320px -21px;
}
.CZ,
.cs {
  background-position: -233px -21px;
}
.PT,
.pt {
  background-position: -30px -21px;
}
.NZ {
  background-position: -349px 0;
}
.PL {
  background-position: -378px 0;
}
.ID {
  background-position: -175px -21px;
}
.JP,
.ja {
  background-position: -262px 0;
}
.VN,
.vi {
  background-position: -117px -21px;
}
.HK {
  background-position: -233px 0;
}
.TW,
.zh {
  background-position: -146px -21px;
}
.CH,
.zh_cn {
  background-position: -88px 0;
}
.IN,
.hi {
  background-position: -262px -21px;
}
.AE {
  background-position: -349px -21px;
}
.SA,
.ar {
  background-position: -59px -21px;
}
.TR,
.tr {
  background-position: -494px 0;
}
.IR,
.fa {
  background-position: -88px -21px;
}
.BR {
  background-position: -204px -42px;
}
.CO {
  background-position: -465px -42px;
}
.MX {
  background-position: -291px 0;
}
.MY,
.ms {
  background-position: -291px -106px;
}
.CYSEC {
  background-position: -107px 0;
}
.USD {
  background-position: -523px 0;
}
.CA {
  background-position: -30px 0;
}

.lang_menu ul {
  padding: 0;
}

.right_part .search {
  display: flex;
  width: 460px;
}

.top.container a.logo {
  background-image: url("../../../logo.png");
  background-repeat: no-repeat;
  width: 120px;
  height: 50px;
}
@media (max-width: 550px) {
  .watch {
    display: none;
  }
  .menu .watch {
    display: none;
  }

  .top.container a.logo {
    width: 90px;
    height: 38px;
    background-size: contain;
  }
}
/*------------------------------------------- mobile menu -------------------------------------------*/

@media (max-width: 1364px) {
  .right_part .search {
    max-width: 300px;
  }
}

@media screen and (max-width: 992px) {
  .content_hover {
    overflow: hidden;
  }

  .black_opacity {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background-color: #000;
    z-index: 5;
  }

  .black_opacity.active {
    display: block;
  }
}

@media (max-width: 900px) {
  .right_part .search {
    display: none;
  }
  .close {
    display: none;
    border: none;
    border-radius: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    background: inherit;
    transform: rotateY(180deg);
    cursor: pointer;
  }
  .icon-search {
    color: #fff;
  }
}
@media (max-width: 800px) {
  .flag_none {
    left: 0px;
    right: auto;
  }
}

/*------------------------------------------- END mobile menu -------------------------------------------*/

/*------------------------------------------- mobile Languages Flag-------------------------------------------*/

@media (max-width: 860px) {
  .flag_none > div {
    width: 50%;
    margin: 0;
  }
}
@media (max-width: 840px) {
  .flag_none {
    top: 30px;
  }
}

@media (max-width: 575px) {
  .flag_none {
    height: 300px;
    overflow: unset;
    overflow-y: scroll;
  }
}

/*------------------------------------------- END mobile Languages Flag -------------------------------------------*/
