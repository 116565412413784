.video_cont_block {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}
.video_cont_block > a {
  display: flex;
  float: left;
  max-width: 322px;
  width: 100%;
  height: 190px;
  position: relative;
}
.video_cont_block:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.video_cont_block:last-child .video_les {
  margin-bottom: 20px;
}
.vid_cont_block_txt {
  width: calc(100% - 342px);
}
.video_less_title {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 15px;
  color: #000;
  text-align: left;
}

body[dir="rtl"] .video_less_title {
  text-align: right;
}

.vdl_footer_bl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vid_cont_block_txt > p {
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #4a4a4a;
  text-align: left;
}
.ifcm-pdf {
  margin-right: 15px;
}
.pdf_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #000000;
  text-align: left;
  display: inline-block;
}

/*MarketOverViewList*/
.new_cont_block {
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.6;
}

.tech_analysis_img img {
  height: 190px;
  width: 375px;
  float: left;
  padding: 0 20px 20px 0;
}

body[dir="rtl"] .new_cont_block {
  text-align: right;
}

.new_cont_block + .vdl_footer_bl {
  margin-bottom: 15px;
}
/*End MarketOverViewList*/

@media (max-width: 1160px) {
  .main_with_left + div .education_section {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .video_less_wrap .video_cont_block {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .video_less_wrap .vid_cont_block_txt {
    width: 100%;
  }
}


/*expert style*/
.expert-block {
  display: flex;
}

.new_cont_block.expert-block img {
  padding: 0;
  width: auto;
}